import { Breakpoint } from "antd/lib/_util/responsiveObserve";
import { createStore, setStateHelper } from "config/store";
import { UseBoundStore } from "zustand";

const initialState = {
  company: undefined,
  documentsList: [],
  selectedDocument: undefined,
  id: undefined,
  assignTo: undefined,
  documentsTotal: undefined,
  documentsTotalRegistered: undefined,
  documentsTotalValidated: undefined,
  endedAt: undefined,
  validateAssignTo: undefined,
  validateEndedAt: undefined,
  loadingPage: false, //savingForm
  loadingDocuments: false,
  disableFormButtons: false, //savingData
  refetchQueries: false,
  screens: {},
  showPreview: true,
};

interface BulkStore {
  company: CompanyType;
  documentsList: DocumentType[];
  selectedDocument: DocumentType;
  id: ID;
  assignTo: {
    id: ID;
  };
  documentsTotal: number;
  documentsTotalRegistered: number;
  documentsTotalValidated: number;
  endedAt: string;
  validateAssignTo: {
    id: ID;
  };
  validateEndedAt: string;
  loadingPage: boolean;
  loadingDocuments: boolean;
  disableFormButtons: boolean;
  refetchQueries: boolean;
  screens: Partial<Record<Breakpoint, boolean>>;
  showPreview: boolean;
  bulkOwnerChanged: boolean;
  resetStore: () => void;
  setSelectedDocument: (selectedDocument: DocumentType) => void;
  setDocumentsList: (documentsList: DocumentType[]) => void;
  unValidateDocument: (currentIndex: number) => void;
  checkDuplicated: ({
    duplicated,
    currentIndex,
  }: {
    duplicated: boolean;
    currentIndex: number;
  }) => void;
  updateDocumentBook: ({
    bookInfo,
    currentIndex,
  }: {
    bookInfo: {
      bookId: ID;
      bookTypeSlug: string;
    };
    currentIndex: number;
  }) => void;
  setCompany: (company: CompanyType) => void;
  setBulk: (bulk: BulkType) => void;
  decrementDocumentsTotalValidated: () => void;
  decrementDocumentsTotalRegistered: () => void;
  finishDocumentsTotalRegistered: () => void;
  finishDocumentsTotalValidated: () => void;
  setLoadingPage: (loadingPage: boolean) => void;
  setLoadingDocuments: (loadingDocuments: boolean) => void;
  setDisablePageButtons: (disableFormButtons: boolean) => void;
  setRefetchQueries: (refetchQueries: boolean) => void;
  setScreens: (screens: Partial<Record<Breakpoint, boolean>>) => void;
  setShowPreview: (showPreview: boolean) => void;
  setBulkOwnerChanged: (bulkOwnerChanged: boolean) => void;
}

const useBulkStore: UseBoundStore<BulkStore> = createStore<BulkStore>(
  (set: (fn: (draft: Record<string, any>) => void) => void) => ({
    ...initialState,
    resetStore: () => {
      set(() => ({
        ...initialState,
      }));
    },
    setSelectedDocument: (selectedDocument: DocumentType) => {
      setStateHelper("selectedDocument", selectedDocument, set);
    },
    setDocumentsList: (documentsList: DocumentType[]) => {
      setStateHelper("documentsList", documentsList, set);
    },
    unValidateDocument: (currentIndex: number) => {
      set((state) => {
        const updateListDocuments = [...state.documentsList].map(
          ({ ...item }, index: number) => {
            if (index === currentIndex) {
              item.validatedAt = null;
            }
            return item;
          },
        );
        return {
          documentsList: updateListDocuments,
        };
      });
    },
    checkDuplicated: ({
      duplicated,
      currentIndex,
    }: {
      duplicated: boolean;
      currentIndex: number;
    }) => {
      set((state) => {
        const listDocument = [...state.documentsList].map(
          ({ ...item }, index) => {
            if (index === currentIndex) {
              item.isDuplicated = duplicated;
            }
            return item;
          },
        );
        return {
          documentsList: listDocument,
        };
      });
    },
    updateDocumentBook: ({
      bookInfo,
      currentIndex,
    }: {
      bookInfo: {
        bookId: ID;
        bookTypeSlug: string;
      };
      currentIndex: number;
    }) => {
      set((state) => {
        const documentsListUpdated = [...state.documentsList].map(
          ({ ...item }, index) => {
            if (index === currentIndex) {
              return {
                ...item,
                ...bookInfo,
              };
            }
            return item;
          },
        );
        return {
          documentsList: documentsListUpdated,
        };
      });
    },
    setCompany: (company: CompanyType) => {
      setStateHelper("company", company, set);
    },
    setBulk: (bulk: BulkType) => {
      set(() => ({ ...bulk }));
    },
    decrementDocumentsTotalValidated: () => {
      set((state) => ({
        documentsTotalValidated: state.documentsTotalValidated - 1,
      }));
    },
    decrementDocumentsTotalRegistered: () => {
      set((state) => ({
        documentsTotalRegistered: state.documentsTotalRegistered - 1,
      }));
    },
    finishDocumentsTotalRegistered: () => {
      set((state) => ({
        documentsTotalRegistered: state.documentsTotal,
      }));
    },
    finishDocumentsTotalValidated: () => {
      set((state) => ({
        documentsTotalValidated: state.documentsTotal,
      }));
    },
    setLoadingPage: (loadingPage: boolean) => {
      setStateHelper("loadingPage", loadingPage, set);
    },
    setLoadingDocuments: (loadingDocuments: boolean) => {
      setStateHelper("loadingDocuments", loadingDocuments, set);
    },
    setDisablePageButtons: (disablePageButtons: boolean) => {
      setStateHelper("disablePageButtons", disablePageButtons, set);
    },
    setRefetchQueries: (refetchQueries: boolean) => {
      setStateHelper("refetchQueries", refetchQueries, set);
    },
    setScreens: (screens: Partial<Record<Breakpoint, boolean>>) => {
      setStateHelper("screens", screens, set);
    },
    setShowPreview: (showPreview: boolean) => {
      setStateHelper("showPreview", showPreview, set);
    },
    setBulkOwnerChanged: (bulkOwnerChanged: boolean) => {
      setStateHelper("bulkOwnerChanged", bulkOwnerChanged, set);
    },
  }),
);

export default useBulkStore;
