import { clientNeoAuth } from "@newrai/api-clients";
import { ApolloProvider } from "@apollo/client";
import { createRoot } from "react-dom/client";
import * as Sentry from "@sentry/browser";
import { hotjar } from "react-hotjar";
import { v1 as uuidv1 } from "uuid";
import App from "./App";

sessionStorage.setItem("tabId", uuidv1());

if (process.env.NODE_ENV === "production" && process.env.REACT_APP_HOTJAR) {
  hotjar.initialize({
    id: Number(process.env.REACT_APP_HOTJAR),
    sv: 6,
  });
}

if (process.env.REACT_APP_ENV !== "local") {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_ENV,
    release: process.env.REACT_APP_CI_COMMIT_SHORT_SHA,
    beforeSend(event) {
      const errorMessage = event?.exception?.values?.[0]?.value;
      const ignoredMessages = [
        "ResizeObserver loop limit exceeded",
        "ResizeObserver loop completed with undelivered notifications.",
        "TypeError: NetworkError when attempting to fetch resource.",
        "Socket closed with event 1011 keepalive ping timeout",
        "Failed to fetch",
        "Invalid Token",
        "Load failed",
        "Not allowed",
      ];

      if (ignoredMessages.some((msg) => errorMessage?.includes(msg))) {
        return null;
      }
      return event;
    },
  });
}

Sentry.setTag("CI_COMMIT_SHORT_SHA", process.env.REACT_APP_CI_COMMIT_SHORT_SHA);

const hashElement = document.getElementById("hash");
if (hashElement) {
  hashElement.innerText = process.env.REACT_APP_CI_COMMIT_SHORT_SHA || "";
}

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <ApolloProvider client={clientNeoAuth}>
    <App />
  </ApolloProvider>,
);
